import { request } from '@/util/http'

export const getList = (current, size, params) => {
  return request({
    url: '/health/userjk/list',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const getDetail = (id) => {
  return request({
    url: '/health/userjk/detail',
    method: 'get',
    params: {
      id
    }
  })
}

export const remove = (ids) => {
  return request({
    url: '/health/userjk/remove',
    method: 'post',
    params: {
      ids
    }
  })
}

export const submit = (row) => {
  return request({
    url: '/health/userjk/submit',
    method: 'post',
    data: row
  })
}

export const clearVipInfo = (ids) => {
  return request({
    url: '/health/userjk/clearVipInfo',
    method: 'post',
    params: {
      ids
    }
  })
}

export const enable = (ids) => {
  return request({
    url: '/health/userjk/enable',
    method: 'post',
    params: {
      ids
    }
  })
}

export const disable = (ids) => {
  return request({
    url: '/health/userjk/disable',
    method: 'post',
    params: {
      ids
    }
  })
}

export const getVipList = (current, size, params) => {
  return request({
    url: '/health/userjk/vipList',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const getBasedOnLevelList = (current, size, params) => {
  return request({
    url: '/health/userjk/BasedOnLevelList',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const getCommonList = (current, size, params) => {
  return request({
    url: '/health/userjk/commonList',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const getInformationDetail = (wxUserId) => {
  return request({
    url: '/health/userjk/informationDetail',
    method: 'get',
    params: {
      wxUserId
    }
  })
}
