<template>
  <basic-container>

    <el-upload
              action="#"
              ref="upload"
              list-type="picture-card"
              :http-request="fnUploadRequest1">
                    <el-button size="small" type="primary">点击上传音频文件</el-button>
          </el-upload>
          <div style="font-size: 20px; margin-top: 10px;"> 音频文件 </div>
    <avue-crud
      :option="option1"
      :data="data1"
      :page.sync="page1"
      ref="crud"
      v-model="form1"
      :permission="permissionList"
      @row-update="rowUpdate"
      @row-del="rowDel"
      :before-open="beforeOpen1"
      @search-change="searchChange"
      @search-reset="searchReset"
      @refresh-change="refreshChange"
      @selection-change="selectionChange"
      @on-load="onLoad">
      <template slot="menuLeft">
      </template>
      <template slot-scope="scope" slot="menu">
        <el-button v-if="boolean" type="text" icon="el-icon-s-operation" size="small" @click.native="openArchive1(scope.row)">播放
        </el-button>
        <el-button v-if="boolean" type="text" icon="el-icon-s-operation" size="small" @click.native="stop()">暂停
        </el-button>
        <audio
        :src="src"
        autoplay="autoplay"
        controls="controls"
        ref="audio"
      >Your browser does not support the audio element.</audio>
        <!-- <el-dialog title="录音播放" :visible.sync="dialogVisible" width="20%" :before-close="stop">
          <audio
          :src="src"
          autoplay="autoplay"
          controls="controls"
          ref="audio"
        >Your browser does not support the audio element.</audio>
        </el-dialog> -->
      </template>
    </avue-crud>

    <el-upload
              action="#"
              ref="upload"
              list-type="picture-card"
              :http-request="fnUploadRequest2">
                    <el-button size="small" type="primary">点击上传视频文件</el-button>
          </el-upload>

          <div style="font-size: 20px; margin-top: 10px;"> 视频文件 </div>
    <avue-crud
      :option="option2"
      :data="data2"
      :page.sync="page2"
      ref="crud"
      v-model="form2"
      :permission="permissionList"
      @row-update="rowUpdate"
      @row-del="rowDel"
      :before-open="beforeOpen2"
      @search-change="searchChange"
      @search-reset="searchReset"
      @refresh-change="refreshChange"
      @selection-change="selectionChange"
      @on-load="onLoad">
      <template slot="menuLeft">
      </template>
      <template slot-scope="scope" slot="menu">
        <el-button v-if="boolean" type="text" icon="el-icon-s-operation" size="small" @click.native="openArchive2(scope.row)">播放
        </el-button>
        <el-button v-if="boolean" type="text" icon="el-icon-s-operation" size="small" @click.native="stop()">暂停
        </el-button>
        <audio
        :src="src"
        autoplay="autoplay"
        controls="controls"
        ref="audio"
      >Your browser does not support the audio element.</audio>
        <!-- <el-dialog title="录音播放" :visible.sync="dialogVisible" width="20%" :before-close="stop">
          <audio
          :src="src"
          autoplay="autoplay"
          controls="controls"
          ref="audio"
        >Your browser does not support the audio element.</audio>
        </el-dialog> -->
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
// import { getList, submit, remove, getDetail } from '@/api/sys/speech_template'
import { getDetail, getList, submit, update, remove } from '@/api/health/SysVoiceVideo'
import { mapGetters } from 'vuex'

const OSS = require('ali-oss')
const client = new OSS({
  region: 'oss-cn-shanghai',
  endpoint: 'oss-cn-shanghai.aliyuncs.com',
  accessKeyId: 'LTAI4GGYDGZjaZQyshkjPBJH',
  accessKeySecret: '5O9VCIRDa6AqHtVj2NDiMUO9Uv2GDe',
  bucket: 'health-planet',
  timeout: 600000
})

export default {
  data () {
    return {
      dialogVisible: false,
      src: '',
      form1: {},
      form2: {},
      ifshow: true,
      test: true,
      boolean: true,
      page1: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      page2: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option1: {
        tip: false,
        border: true,
        index: true,
        viewBtn: false,
        editBtn: this.boolean,
        selection: true,
        delBtn: true,
        addBtn: false,
        menuWidth: 300,
        column: [
          {
            label: '报告名称',
            prop: 'fileName',
            cell: true
          },
          {
            label: '模块',
            prop: 'modular',
            addDisplay: false,
            editDisabled: false,
            cell: true,
            span: 24
          },
          {
            label: '报告类型',
            prop: 'type',
            type: 'select',
            dicData: [
              { label: '未填写', value: '0' },
              { label: '音频', value: '1' },
              { label: '视频', value: '2' }
            ]
          },
          {
            label: '创建时间',
            prop: 'createTime',
            addDisplay: false,
            editDisabled: true,
            span: 24,
            cell: false
          },
          {
            label: '修改时间',
            prop: 'updateTime',
            addDisplay: false,
            editDisabled: true,
            span: 24,
            cell: false
          }
        ]
      },
      option2: {
        tip: false,
        border: true,
        index: true,
        viewBtn: false,
        editBtn: this.boolean,
        selection: true,
        delBtn: true,
        addBtn: false,
        menuWidth: 300,
        column: [
          {
            label: '报告名称',
            prop: 'fileName',
            cell: true
          },
          {
            label: '模块',
            prop: 'modular',
            addDisplay: false,
            editDisabled: false,
            cell: true,
            span: 24
          },
          {
            label: '报告类型',
            prop: 'type',
            type: 'select',
            dicData: [
              { label: '未填写', value: '0' },
              { label: '音频', value: '1' },
              { label: '视频', value: '2' }
            ]
          },
          {
            label: '创建时间',
            prop: 'createTime',
            addDisplay: false,
            editDisabled: true,
            span: 24,
            cell: false
          },
          {
            label: '修改时间',
            prop: 'updateTime',
            addDisplay: false,
            editDisabled: true,
            span: 24,
            cell: false
          }
        ]
      },
      data1: [],
      data2: []
    }
  },
  computed: {
    ...mapGetters(['permission']),
    permissionList () {
      return {
        addBtn: this.permission.qa_qa_add,
        viewBtn: this.permission.qa_qa_view,
        delBtn: this.permission.qa_qa_delete,
        editBtn: this.permission.qa_qa_edit
      }
    },
    ids () {
      const ids = []
      this.selectionList.forEach(ele => {
        ids.push(ele.id)
      })
      return ids.join(',')
    }
  },
  created () {
    this.wxUserId = this.$route.query.wxUserId
    this.type = this.$route.query.type
  },
  methods: {
    async fnUploadRequest1 (options) {
      try {
        console.log('test')
        console.log(options)
        const file = options.file // 拿到 file
        console.log(file)
        const fileName = file.name.substr(0, file.name.lastIndexOf('.'))
        const fileType = file.name.substr(file.name.lastIndexOf('.') + 1)
        console.log(file.name.lastIndexOf('.'))
        console.log(file.name.substr(file.name.lastIndexOf('.') + 1))
        const date = new Date().getTime()
        const fileNames = `${date}_${fileName}` + '.' + fileType// 拼接文件名，保证唯一，这里使用时间戳+原文件名
        // 上传文件,这里是上传到OSS的文件夹下
        client.put(fileNames, file).then(res => {
          if (res.res.statusCode === 200) {
            // 把上传成功的值保存到imageUrl中
            console.log('ceshiyixa')
            console.log(res)
            options.onSuccess(res)
            const params = { url: res.url, modular: '模块', type: 1 }
            submit(params).then(() => {
                this.onLoad(this.page)
                this.$message({
                  type: 'success',
                  message: '操作成功!'
                })
              }).catch(() => {
                this.onLoad(this.page)
              })
          } else {
            options.onError('上传失败')
          }
        })
      } catch (e) {
        options.onError('上传失败')
      }
    },

    async fnUploadRequest2 (options) {
      try {
        console.log('test ok')
        console.log(options)
        const file = options.file // 拿到 file
        console.log(file)
        const fileName = file.name.substr(0, file.name.lastIndexOf('.'))
        const fileType = file.name.substr(file.name.lastIndexOf('.') + 1)
        console.log(file.name.lastIndexOf('.'))
        console.log(file.name.substr(file.name.lastIndexOf('.') + 1))
        const date = new Date().getTime()
        const fileNames = `${date}_${fileName}` + '.' + fileType// 拼接文件名，保证唯一，这里使用时间戳+原文件名
        // 上传文件,这里是上传到OSS的文件夹下
        client.put(fileNames, file).then(res => {
          if (res.res.statusCode === 200) {
            // 把上传成功的值保存到imageUrl中
            console.log('ceshiyixa')
            console.log(res)
            options.onSuccess(res)
            const params = { url: res.url, modular: '模块', type: 2 }
            submit(params).then(() => {
                this.onLoad(this.page)
                this.$message({
                  type: 'success',
                  message: '操作成功!'
                })
              }).catch(() => {
                this.onLoad(this.page)
              })
          } else {
            options.onError('上传失败')
          }
        })
      } catch (e) {
        options.onError('上传失败')
      }
    },

    openArchive1 (row) {
      const url = row.url
      const typeDetails = url.substring(url.length - 3)
      if (typeDetails === 'pdf') {
        window.open(row.url, '_blank')
      } else if (typeDetails === 'png' || typeDetails === 'jpg') {
        const newWindow = window.open('')
        newWindow.document.body.innerHTML = '<img src="' + row.url + '" alt="">'
      } else {
        this.src = row.url
        this.play()
      }
    },

    openArchive2 (row) {
      const url = row.url
      const typeDetails = url.substring(url.length - 3)
      if (typeDetails === 'pdf') {
        window.open(row.url, '_blank')
      } else if (typeDetails === 'png' || typeDetails === 'jpg') {
        const newWindow = window.open('')
        newWindow.document.body.innerHTML = '<img src="' + row.url + '" alt="">'
      } else {
        this.src = row.url
        this.play()
      }
    },

    play () {
      this.dialogVisible = true
      this.$refs.audio.play()
    },

    stop () {
      this.src = ''
      this.dialogVisible = false
      this.$refs.audio.pause()
      this.$refs.audio.currentTime = 0
    },

    rowUpdate (row, index, done, loading) {
      update(row).then(() => {
          done()
          this.onLoad(this.page)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
        }).catch(() => {
          loading()
        })
    },
    rowDel (row) {
      this.$confirm('确定将选择数据删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          return remove(row.id)
        })
        .then(() => {
          this.onLoad(this.page)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
        })
    },
    beforeOpen1 (done, type) {
      if (['edit', 'view'].includes(type)) {
        getDetail(this.form1.id).then(res => {
          this.form1 = res.data
        })
      }
      done()
    },
    beforeOpen2 (done, type) {
      if (['edit', 'view'].includes(type)) {
        getDetail(this.form2.id).then(res => {
          this.form2 = res.data
        })
      }
      done()
    },
    searchReset () {
      this.onLoad(this.page)
    },
    searchChange (params, done) {
      this.onLoad(this.page, params)
      done()
    },
    selectionChange (list) {
      this.selectionList = list
    },
    refreshChange (data) {
      this.onLoad(this.page, data.searchForm)
    },
    onLoad (page) {
      getList(page.currentPage, page.pageSize, { type: 1 }).then(res => {
          const data = res.data
          this.page1.total = data.total
          this.data1 = data.records
          console.log('测试')
          console.log(res)
        })
        getList(page.currentPage, page.pageSize, { type: 2 }).then(res => {
          const data = res.data
          this.page2.total = data.total
          this.data2 = data.records
          console.log('测试')
          console.log(res)
        })
    }
  }
}
</script>

<style></style>
